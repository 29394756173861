/** @jsx jsx */
import { Link } from "gatsby";
import { jsx, css, keyframes } from "@emotion/react";

import { graphql } from "gatsby";

import Page from "../layouts/page";


const outerBox = css`
display: flex;
justify-content: center;
`

const innerBox = css`
text-align: left;
width: 50%;
`

const postStyles = css`
padding: 20px;

h2 {
    margin-top: 2px;
    font-size: 2em;
}

hr {
    margin-top: 20px;
}
`

const linkStyles = css`
color: black;
text-decoration: none;
`

const IndexPage = ({
  data: {
    allMdx: { edges },
  },
}: {
  data: { allMdx: { edges: Record<string, any>[] } };
}) => {

  return (
    <Page title="Home">
        <div css={outerBox}>
            <div css={innerBox}>
                {edges.map((post) => (
                    <Link css={linkStyles} to={`/blog/${post.node.frontmatter.slug}`}><div css={postStyles} key={post.node.frontmatter.slug}>
                        <h2>{post.node.frontmatter.title}</h2>
                        <h3>{post.node.frontmatter.date} &#8226; {post.node.frontmatter.author.name}</h3>
                        {post.node.excerpt}
                        <hr/>
                    </div></Link>
                ))}
            </div>
        </div>
    </Page>
  );
};

export const query = graphql`
  query BlogPosts {
    allMdx {
      edges {
        node {
          excerpt(pruneLength: 220)
          frontmatter {
            title
            slug
            date
            author {
              name
            }
          }
        }
      }
    }
  }
`;

export default IndexPage;
